import { render, staticRenderFns } from "./SaleDetails.vue?vue&type=template&id=2e373d45&scoped=true"
import script from "./SaleDetails.vue?vue&type=script&lang=js"
export * from "./SaleDetails.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e373d45",
  null
  
)

export default component.exports